import ActivitySearchResult from '@/components/activities/list-item';
import ActivitiesSearchSection from '@/components/activities/search-section';
import Layout from '@/components/layout';
import { MapMarker } from '@/components/map';
import MapPopup from '@/components/map/popup';
import List from '@/components/search/list';
import Seo from '@/components/seo';
import { useCities } from '@/store/hooks/cities';
import { useActivities } from '@/store/hooks/use-activities';
import React from 'react';

const Activities: React.FunctionComponent = () => {
  const { all } = useActivities();
  const {
    actions: { setCurrentSuggestion: setCurrentCitySuggestion },
  } = useCities();
  const listItems = React.useMemo(
    () =>
      all.map((activity, index) => (
        <div id={`activity-${index}`} key={activity._id}>
          <ActivitySearchResult activity={activity} index={index} />
        </div>
      )),
    [all],
  );

  const mapMarkers = React.useMemo(
    (): MapMarker[] =>
      all.map((activity, index) => ({
        text: (
          <MapPopup
            key={activity._id}
            title={activity.title}
            subTitle={activity?.category?.title}
            listItemHref={`#activity-${index}`}
          />
        ),
        position: [activity.location.lat, activity.location.lon],
      })),
    [all],
  );

  return (
    <Layout>
      <ActivitiesSearchSection />
      <Seo title="Agenda des événements, activités et séminaires" />
      <List
        listItems={listItems}
        noResult={{
          message: `Aucune activité ou référence trouvée pour la période donnée`,
          button: {
            label: `Essayez sur toute la belgique`,
            onClick: () => setCurrentCitySuggestion(``),
          },
        }}
        color="secondary"
        mapMarkers={mapMarkers}
      />
    </Layout>
  );
};

export default Activities;
