import { faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'gatsby';
import React from 'react';

interface MapPopupProps {
  title: string;
  subTitle?: string;
  listItemHref: string;
  link?: {
    to: string;
    content: React.ReactNode;
  };
}

const MapPopup: React.FunctionComponent<MapPopupProps> = ({
  title,
  subTitle,
  listItemHref,
  link,
}) => (
  <>
    <p className="title is-6">{title}</p>
    {subTitle && <p className="subtitle is-7">{subTitle}</p>}
    <a
      type="button "
      className="button is-small is-inverted is-primary has-text-weight-bold mr-3 is-hidden-mobile"
      href={listItemHref}
    >
      <span>Voir la fiche</span>
      <span className="icon">
        <FontAwesomeIcon icon={faUser} />
      </span>
    </a>
    {link && (
      <Link
        to={link.to}
        className="button is-primary is-small has-text-weight-bold"
      >
        {link.content}
      </Link>
    )}
  </>
);
export default MapPopup;
