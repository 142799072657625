import React from 'react';
import { Link } from 'gatsby';
import image from '../../assets/images/etre-plus-logo-grayscale.jpg';
import { Article } from '../../../../backend/src/models/articles.model';

export interface ESResult<T> {
  _index: string;
  _type: string;
  _id: string;
  _score: number;
  _source: T;
}

export interface ArticleThumbnailProps {
  article: Article;
}

const ArticleThumbnail: React.FunctionComponent<ArticleThumbnailProps> = ({
  article,
}) => (
  <div
    className="card"
    style={{
      maxHeight: `410px`,
      overflow: `hidden`,
      minWidth: `180px`,
    }}
  >
    <Link to={article.meta.websiteSlug} style={{ overflow: `hidden` }}>
      <header className="card-header">
        <figure
          className="image is-5by3"
          style={{ height: `100%`, width: `100%` }}
        >
          {article && article.images && article.images[0] ? (
            <img
              src={`https://www.agendaplus.be${article.images[0]}`}
              style={{ objectFit: `cover` }}
              alt=""
            />
          ) : (
            <img
              src={image}
              alt={article.content.stripped}
              style={{ objectFit: `cover` }}
            />
          )}
        </figure>
      </header>
      <div className="card-content" style={{ overflow: `hidden` }}>
        <h3
          className="title is-5"
          style={{
            overflow: `hidden`,
            WebkitLineClamp: 2,
            display: `-webkit-box`,
            WebkitBoxOrient: `vertical`,
            minHeight: `5ex`,
            lineHeight: `2.5ex`,
            wordBreak: `break-word`,
          }}
          dangerouslySetInnerHTML={{ __html: article.title }}
        />
        <p
          dangerouslySetInnerHTML={{ __html: article.content.stripped }}
          style={{
            overflow: `hidden`,
            WebkitLineClamp: 5,
            display: `-webkit-box`,
            WebkitBoxOrient: `vertical`,
            minHeight: `5ex`,
            lineHeight: `2.5ex`,
            wordBreak: `break-word`,
          }}
        />
      </div>
    </Link>
  </div>
);

export default ArticleThumbnail;
