import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan } from '@fortawesome/free-solid-svg-icons';
import useStore from '@/store';
import { Link } from 'gatsby';
import Map, { MapMarker } from '../map';
import ArticleThumbnail from '../articles/thumbnail';

interface ListProps {
  listItems: React.ReactNode[];
  noResult?: {
    message?: string;
    button?: {
      label: string;
      onClick?(): void;
    };
  };
  mapMarkers: MapMarker[];
  color?: string;
}

const List: React.FunctionComponent<ListProps> = ({
  listItems,
  noResult,
  mapMarkers,
  color = `primary`,
}) => {
  const allArticles = useStore((state) => state.articles.all);

  return (
    <section className="section" id="dataSearch">
      <div className="container">
        <div className="columns">
          <div className="column">
            {listItems.length ? (
              listItems
            ) : (
              <div>
                <div className="has-text-centered mt-5">
                  <FontAwesomeIcon icon={faBan} size="2x" />
                  <h4>
                    <p className="mb-3">
                      {noResult?.message || `Aucun résultat`}
                    </p>
                    {noResult?.button && (
                      <button
                        type="button"
                        className={`button is-small is-${color}`}
                        onClick={() => noResult?.button?.onClick()}
                      >
                        {noResult.button.label}
                      </button>
                    )}
                  </h4>
                </div>
              </div>
            )}
          </div>
          <div
            className="column is-6"
            style={{
              position: `sticky`,
              top: 10,
              alignSelf: `flex-start`,
            }}
          >
            <Map markers={mapMarkers} />
            {allArticles[0] && (
              <div className="pt-5">
                <div className="columns is-multiline is-mobile">
                  {allArticles.map((article, index) => (
                    <div
                      className={[
                        `column is-6-mobile is-6-tablet is-6-desktop is-4-widescreen`,
                        index === 2
                          ? `is-hidden-touch is-hidden-desktop-only`
                          : undefined,
                      ].join(` `)}
                      key={article._id}
                    >
                      <ArticleThumbnail article={article} />
                    </div>
                  ))}
                </div>
                <p className="has-text-centered mt-5">
                  <Link
                    to="/articles"
                    className={`button is-${color} has-text-weight-bold`}
                  >
                    Rechercher dans tous les articles
                  </Link>
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default List;
